@import 'variables';
@import 'padding-margin';
@import 'components';
@import 'components/table.scss';
@import 'components/settings.scss';
@import 'components/contact-form.scss';
@import 'components/login.scss';
@import 'components/profile.scss';
@import 'components/profile-recipes.scss';
@import 'components/recipes-grid.scss';
@import 'components/sidebar.scss';
@import 'recipe-form/media.scss';
@import 'recipe-form/recipe.scss';
@import 'recipe-form/extra.scss';
@import 'recipe-form/preview.scss';
@import 'recipe-form/subtitles-check.scss';
@import 'components/modal.scss';
@import 'form';

.shown {
    opacity: 1;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
    margin-bottom: 0px;
}

:focus-visible {
    outline: none;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.flex {
    display: flex;
    align-items: center;
}

.jc-flex-end {
    justify-content: flex-end;
}

.jc-center {
    justify-content: center;
}

.jc-flex-start {
    justify-content: flex-start;
}

.clickable {
    cursor: pointer;
}

.jc-space-btwn {
    justify-content: space-between;
}
