.settings {
    display: flex;
    flex-direction: column;
    align-self: stretch;
    justify-content: space-between;

    max-width: 868px;
    width: 100%;

    padding: 97px 80px 68px 80px;
}

.settings>div {
    width: 100%;
    display: flex;
    flex-direction: row;
    gap: 34px
}

.settings>div>div:first-of-type {
    flex: auto;
}

form.settings .user-image,
form.profile .user-image {
    position: relative;
    width: 190px;
    height: 190px;
    border-radius: 190px;
    background-color: #ddd;
}

form.profile .user-image {
    margin-right: 80px;
}

form.settings .user-image img,
form.profile .user-image img {
    object-fit: cover !important;
    background-color: #ddd;
    width: 190px;
    height: 190px;
    border-radius: 190px;
}

form.profile .user-image label.hidden {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 190px;
    height: 190px;
    cursor: pointer;
}

form.settings .user-image label.hidden {
    position: absolute;
    top: 0px;
    left: 0px;
    text-align: center;
    width: 190px;
    height: 220px;
    cursor: pointer;
}

form.settings .user-image label:not(.hidden) {
    text-align: center;
    width: 190px;
}

form.settings .user-image .spinner-border {
    position: absolute;
    top: 0px;
    left: 0px;
    width: 190px;
    height: 190px;
}


.settings .btn-link {
    display: block;
}

.settings>div>div>.form-group {
    margin-bottom: 14px;
}

.settings .btn-link {
    margin-bottom: 16px;
}

.settings>div>div>.form-group:last-of-type,
.settings .btn-link:last-of-type {
    margin-bottom: 64px;
}

.settings .button-row,
.settings-modal .button-row {
    display: flex;
    flex-direction: row;
    justify-content: center;

    gap: 24px;

    max-width: 868px;
    width: 100%;
}

.settings-modal form {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 131px 148px 105px 148px;
}

.settings-modal form .form-group {
    margin-bottom: 15px;
    max-width: 570px;
    width: 100%;
}

.settings-modal form .button-row {
    margin-top: 154px;
}