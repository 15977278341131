.contact-form-container {
    display: flex;

    height: 100%;
    flex: auto;
    justify-content: flex-start;
}

.contact-form-container>.form,
.contact-form-container>.heading {
    flex: auto;
    max-width: 50%;
    z-index: 99;
}

.contact-form-container .heading {
    padding: 82px;
    padding-top: calc(50vh - 36px - 177px - calc(100.8px * 1.5));
}

.contact-form-container .heading:has(button) {
    padding: 82px;
    padding-top: 72px;
}

.contact-form-container .heading:has(button) h1 {
    padding-top: calc(50vh - 36px - 177px - calc(100.8px * 1.5));
}

.contact-form-container .heading:has(button) p:first-of-type {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.48px;
    margin-top: 24px;
}

.contact-form-container .heading:has(button) p:last-of-type {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.352px;
    text-transform: uppercase;
    margin-top: 64px;
}

.contact-form-container .heading .btn-link {
    outline: none !important;
    background-color: transparent !important;
    border: none !important;
    text-decoration: none !important;
    color: #1E1E1E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 150%;
    /* 24px */
    letter-spacing: -0.352px;
}

/*
.contact-form-container svg {
    position: absolute;
    z-index: 0;
}*/

.contact-form-container h1 {
    color: #F24130;
    font-family: Chapman;
    font-size: 96px;
    font-style: normal;
    font-weight: 700;
    line-height: 105%;
    /* 100.8px */
    letter-spacing: -1.92px;
    text-transform: uppercase;
}

.contact-form-container>.form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 72px;
    padding-left: 0px;
}

.contact-form-container>.form>form {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 422px;
}

.contact-form-container>.form>div:not(.request) {
    justify-content: flex-end;
    display: flex;
}

.contact-form-container>.form>div.request {
    justify-content: flex-start;
    display: flex;
    flex-direction: column;
    gap: 36px;
}

.contact-form-container>.form>div.request p {
    margin-bottom: 0px;
}

.contact-form-container>.form>div.request h2 {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    /* 212.5% */
    letter-spacing: -0.352px;
    text-transform: uppercase;
}

.contact-form-container>.form>div.request label {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 34px;
    /* 141.667% */
    letter-spacing: -0.528px;
}

.contact-form-container>.form>div.request p {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    /* 141.667% */
    letter-spacing: -0.528px;
}

.contact-form-container>.form>div>button {
    max-width: 422px;
}

.contact-form-container input,
.contact-form-container .select__control {
    @extend .base-input;

    background-color: transparent;
    border-radius: 53px !important;
    height: 48px !important;
    flex-shrink: 0 !important;

    @extend .base-input-text;
}

.contact-form-container .select__control {
    padding-left: 0px !important;
}

.contact-form-container .select__control:has(.select__placeholder) {
    background-color: transparent;
}

.contact-form-container .select__control:not(:has(.select__placeholder)) {
    background-color: white;
}

.contact-form-container input:not(:placeholder-shown) {
    background-color: white;
}

.contact-form-container input::placeholder {
    color: #a71616;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.32px;
}