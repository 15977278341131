.language-title-row>.form-group>div {
    flex: 1;
    width: 100%;
}

.form-group>div.basic-single,
.form-group-invisible>div.basic-single {
    flex: 1;
    width: 100%;
}

.preparation ul {
    padding-left: 0px;
    margin-bottom: 0px;
}

.preparation li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.preparation li:last-of-type {
    margin-bottom: 0px;
}

.preparation li input:focus {
    border: 1px solid #898989;
}

.recipe-form-small .button-row {
    margin-top: calc(84px - 16px);

    display: flex;
    flex-direction: row;
    justify-content: center;

    gap: 24px;
}

.radio-group {
    display: flex;
    flex-direction: row;
    gap: 36px;
    color-scheme: only light;
}

.radio-group>div {
    display: flex;
    flex-direction: row;
}

.radio-group>div>label {
    align-self: center;
    color: #000;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;

}

.radio-group>div>input {
    width: 22px;
    margin-right: 4px;
    accent-color: #b18e09; //$yellow;
}

.deco-number-input {
    display: flex;
    position: relative;
    width: min-content !important;
}

.deco-number-input input {
    padding-left: 10px !important;
    padding-right: 10px !important;
    width: 100px !important;
    text-align: center;

    color: #1E1E1E !important;
    font-family: Inter;
    font-size: 20px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: normal !important;
    letter-spacing: -0.528px !important;
}

.deco-number-input>button {
    position: absolute;
}

.deco-number-input>button:first-of-type {
    left: 0;
}

.deco-number-input>button:last-of-type {
    right: 0;
}