.modal {
    height: calc(100vh - 36px);
}

label {
    color: #000;
    text-align: left;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 120%;
    /* 16.8px */
    letter-spacing: -0.28px;
}

.error {
    @extend label;
    color: #f24130;
}

.btn-label {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 42px;
    /* 175% */
    letter-spacing: -0.48px;
}

.btn-label-bold {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.48px;
}

.btn-base {
    border-radius: 48px;
    display: flex;
    max-width: 422px;
    width: 100%;
    height: 57px;
    padding: 9px 24px;
    justify-content: center;
    align-items: center;
}

.btn-solid {
    @extend .btn-base;
    background-color: $yellow;
    border: none;

    @extend .btn-label;
}

.btn-outline {
    @extend .btn-base;
    border: 1.25px solid #000;
    background-color: transparent;

    @extend .btn-label;
}

.login-container .btn-outline {
    border-color: #0d6efd;
    align-self: center;
    margin-top: calc(101px - 24px);

    @extend .btn-label;
    color: #0d6efd;
}

.btn-link-base {
    outline: none !important;
    background-color: transparent !important;
    border: none !important;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    letter-spacing: -0.352px;
}

.settings-btn-link {
    @extend .btn-link-base;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: -0.352px;
    text-decoration: none !important;
}

.btn-link {
    @extend .settings-btn-link;
    color: #0d6efd !important;
}

.btn-link.danger {
    @extend .settings-btn-link;
    color: #f24130;
}

.login-container .btn-link {
    @extend .btn-link-base;
    color: #0d6efd;
    font-weight: 400;
    line-height: normal;
    text-decoration-line: underline;
    margin-top: calc(18px - 24px);
}

.contact-form-container .heading .btn-link,
.login-container > .btn-link {
    @extend .btn-link-base;
    line-height: 150%;
    /* 24px */
    font-weight: 600;
    text-decoration: none !important;
    color: #1e1e1e !important;
}

.modal-body-media .form-group .btn-link {
    width: 100%;
    color: #0d6efd;
    text-align: center;
    text-decoration: none;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    /* 16.8px */
    letter-spacing: -0.28px;
    margin-top: 8px;
}

.btn-base-small {
    display: inline-flex;
    padding: 5px 20px 6.25px 20px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 10px;
    flex-wrap: wrap;

    border-radius: 62.5px;
    border: none;

    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 25px;
    /* 138.889% */
    letter-spacing: -0.54px;
}

.btn-small {
    @extend .btn-base-small;

    background: #fff;
}

.btn-small-active {
    @extend .btn-base-small;

    background: #ddd;
}

.btn-password-base {
    width: 48px;
    height: 48px;
    border-radius: 48px;
    flex-shrink: 0;
    outline: none !important;
    border: none !important;
    padding: 0px;
    position: absolute;
    right: 5px;
    bottom: 5px;
}

.btn-hide {
    @extend .btn-password-base;
}

.btn-show {
    @extend .btn-password-base;
}

.carousel-btn {
    display: inline-flex;
    padding: 4px 16px 5px 16px;
    justify-content: center;
    align-items: center;
    align-content: center;
    gap: 8px;
    flex-wrap: wrap;
    border-radius: 50px;
    background: $yellow;
    border: none;

    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */
    letter-spacing: -0.42px;
}

.carousel-btn:has(svg) {
    display: inline-flex;
    padding: 0px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;
    background: transparent;
    border: none;
}

.recipe-header,
.settings-header {
    height: 64px;
    background-color: $yellow;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipe-header h1,
.settings-header h1 {
    color: #000;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 36px */
    letter-spacing: -0.48px;
}

.recipe-header .carousel-btn {
    position: absolute;
    right: 16px;
}

.loading {
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    justify-content: center;
    align-self: center;
}

.overflow-y {
    overflow-y: visible;
}
.overflow-x {
    overflow-x: visible;
}

//POSITION
.position-inherit {
    position: inherit;
}

.position-absolute {
    position: absolute;
}

.relative {
    position: relative;
}

.sticky {
    position: sticky;
}

//DISPLAY
.display-none {
    display: none;
}
.display-inline {
    display: inline;
}
.display-inline-block {
    display: inline-block;
}

.row {
    display: flex !important;
    margin: 0px;
}

//FLEX

.flex-row {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
}

.flex-row-wrapped {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.flex-column {
    display: flex;
    flex-direction: column;
    flex: 1;
}

.flex {
    flex: 1 1;
}

.flex-2 {
    flex: 2 2;
}

.flex-3 {
    flex: 3 3;
}

.display-flex {
    display: flex !important;
}

//ALIGNMENT
.ver-align-middle {
    vertical-align: middle;
}

.ver-align-bottom {
    vertical-align: baseline !important;
}

.align-items-center {
    align-items: center;
}

.align-self-center {
    align-self: center;
}

.stick-to-bottom {
    position: sticky;
    bottom: 0px;
}

.center-right-absolute {
    position: absolute;
    right: 10px;
    top: 10px;
}
