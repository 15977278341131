.recipes-grid {
    display: flex;
    width: inherit;
    flex-flow: row wrap;
    row-gap: 9px;
    column-gap: 9px;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    flex-direction: row;
}

.recipe-item {
    flex-basis: calc(calc(100% - 9px - 9px) / 3);
    aspect-ratio: 1;
    background-color: #DDD;
    position: relative;
}

.recipe-item img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.recipe-item .info-row {
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.recipe-item label {
    border-radius: 4px;
    background: $yellow;
    width: min-content;
    height: 37px;
    padding: 0px 6px;

    color: #121212;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.4px;
}

.recipe-item div:not(.info-row):has(svg) {
    background-color: #12121280;
    border-radius: 4px;
}

.full-invisible-btn {
    position: absolute;
    top: 0px;
    left: 0px;

    width: 100%;
    height: 100%;

    background-color: transparent !important;
    outline: none !important;
    border: none !important;
}