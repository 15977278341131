.subtitles-preview {
    background-color: $yellow;
    padding: 64px 80px;
    cursor: pointer;
}

.subtitles {
    padding: 64px 80px;
}

.base-subtitles-text {
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.4px;
}

.subtitles-preview p {
    @extend .base-subtitles-text;
    color: #FFF;
}

.subtitles-preview p.highlight {
    color: #000;
    @extend .base-subtitles-text;
}

.subtitles textarea {
    max-height: calc(100vh - 64px - 64px - 37px);
}