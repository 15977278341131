.modal-body-recipe {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
    flex-grow: 1;
}

.modal-body-recipe>.spinner {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    height: -webkit-fill-available;
}

.modal-body-recipe .recipe-form-small hr {
    margin-top: 80px;
    height: 1px;
    opacity: 0.5;
    color: #DDD;
}

.modal-body-recipe .recipe-form-small .carousel-btn {
    height: 57px;
}

.recipe-modal {
    min-height: calc(100vh - 36px);
}

.modal-dialog {
    margin-top: 0px;
    margin-bottom: 0px;
}

.modal-body-media,
.modal-body-subtitles,
.modal-body-preview,
.modal-body-translation {
    display: flex;
    flex-direction: row;
    flex: 1;
}

.modal-body-media>div:first-child,
.modal-body-subtitles>div:first-child,
.modal-body-preview>div:first-child,
.modal-body-translation>div:first-child {
    width: 1165px;
    display: flex;
    flex-direction: column;
    position: relative;
}

.modal-body-media>div:first-child,
.modal-body-preview>div:first-child {
    height: calc(100vh - 38px);
}

.modal-body-preview {
    overflow-y: hidden !important;
}

.modal-body-preview .recipe-content-preview {
    overflow-y: auto;
}

@media screen and (max-width: 1600px) {

    .modal-body-media>div:first-child,
    .modal-body-subtitles>div:first-child,
    .modal-body-preview>div:first-child,
    .modal-body-translation>div:first-child,
    .main-form-content .recipe-video {
        width: 900px !important;
    }
}

@media screen and (max-width: 1190px) {

    .modal-body-media>div:first-child,
    .modal-body-subtitles>div:first-child,
    .modal-body-preview>div:first-child,
    .modal-body-translation>div:first-child {
        width: 65% !important;
    }

    .main-form-content .recipe-video {
        width: 100% !important;
    }
}

.modal-body-media>div:last-child,
.modal-body-subtitles>div:last-child,
.modal-body-preview>div:last-child,
.modal-body-translation>div:last-child {
    flex: 1;
}

@media (min-width: 1190px) {
    .modal-xl {
        --bs-modal-width: 1165px;
    }
}

.modal-content {
    --bs-modal-border-radius: 0px;
}


.modal-body-media .button-row,
.modal-body-subtitles .button-row,
.modal-body-preview .button-row,
.modal-body-translation .button-row {
    position: absolute;
    max-width: 1165px;
    width: 100%;
    bottom: 44px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    gap: 24px;
}


.video-orientation-button-row {
    position: absolute;
    max-width: 1165px;
    width: 100%;
    top: 22px;

    display: flex;
    flex-direction: row;
    justify-content: center;

    z-index: 9999;

    gap: 24px;
}


.video-orientation-button-row .btn-link {
    /*   background-color: #476580 !important;
    box-shadow: 0px 0px 20px 9px #476580;*/
    background-color: #000000 !important;
    box-shadow: 0px 0px 5px 6px #000;
    border-radius: 5px;
    width: auto;
    height: auto;
}

@media screen and (max-width: 1190px) {

    .modal-body-media .button-row,
    .modal-body-subtitles .button-row,
    .modal-body-preview .button-row,
    .modal-body-translation .button-row {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.modal-body-media .form-group .title-image {
    width: 100%;
    display: flex;
    justify-content: center;

}

.modal-body-media .form-group .title-image img {
    width: 84px;
    height: 84px;
    object-fit: cover;
}

.modal-body-recipe {
    display: flex;
    flex-direction: column;
    height: -webkit-fill-available;
}

.modal-body-recipe>.spinner {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    height: -webkit-fill-available;
}

.modal-body-recipe .recipe-form-small hr {
    margin-top: 80px;
    height: 1px;
    opacity: 0.5;
    color: #DDD;
}

.modal-body-recipe .recipe-form-small .carousel-btn {
    height: 57px;
}

.modal-body-thank-you {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    flex: auto;
}

.modal-body-thank-you>div {
    flex: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.modal-body-thank-you>div>p {
    text-align: center;
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 32px;
    /* 133.333% */
    letter-spacing: -0.48px;
}

.modal-body-thank-you>div>.button-row {
    display: flex;
    justify-content: center;
    margin-bottom: 57px;
}