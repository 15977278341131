@for $i from 0 through 300 {
    .p-#{$i} {
        padding: #{$i}px !important;
    }
    .p-l-#{$i} {
        padding-left: #{$i}px !important;
    }
    .p-r-#{$i} {
        padding-right: #{$i}px !important;
    }
    .p-t-#{$i} {
        padding-top: #{$i}px !important;
    }
    .p-b-#{$i} {
        padding-bottom: #{$i}px !important;
    }
}

@for $i from 0 through 300 {
    .m-#{$i} {
        margin: #{$i}px !important;
    }
    .m-l-#{$i} {
        margin-left: #{$i}px !important;
    }
    .m-r-#{$i} {
        margin-right: #{$i}px !important;
    }
    .m-t-#{$i} {
        margin-top: #{$i}px !important;
    }
    .m-b-#{$i} {
        margin-bottom: #{$i}px !important;
    }
}

@for $i from 0 through 300 {
    .p-#{$i}rem {
        padding: #{$i}rem !important;
    }
    .p-l-#{$i}rem {
        padding-left: #{$i}rem !important;
    }
    .p-r-#{$i}rem {
        padding-right: #{$i}rem !important;
    }
    .p-t-#{$i}rem {
        padding-top: #{$i}rem !important;
    }
    .p-b-#{$i}rem {
        padding-bottom: #{$i}rem !important;
    }
}

@for $i from 0 through 300 {
    .m-#{$i}rem {
        margin: #{$i}rem !important;
    }
    .m-l-#{$i}rem {
        margin-left: #{$i}rem !important;
    }
    .m-r-#{$i}rem {
        margin-right: #{$i}rem !important;
    }
    .m-t-#{$i}rem {
        margin-top: #{$i}rem !important;
    }
    .m-b-#{$i}rem {
        margin-bottom: #{$i}rem !important;
    }
}

.p-05rem {
    padding: 0.5rem !important;
}

.m-l-auto {
    margin-left: auto;
}
.m-r-auto {
    margin-right: auto;
}
