.sidebar {
    width: 521px;
    padding: 98px 85px;
}

@media screen and (max-width: 1190px) {
    .sidebar {
        width: 15%;
        min-width: 200px;
        padding: 98px 1%;
    }

}

.sidebar li {
    border-top: 1px solid #dcdcdc;
}

.sidebar li:last-of-type {
    border-bottom: 1px solid #dcdcdc;
}

.sidebar-a-base {
    color: black !important;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    line-height: 24px;
    letter-spacing: -0.4px;
    text-align: left;

    padding: 20px 24px;
}

.sidebar a {
    display: flex;
    align-items: center;

    background-color: transparent !important;
    @extend .sidebar-a-base;
    font-weight: 400;

}

.sidebar .active {
    @extend .sidebar-a-base;
    font-weight: 600;
}

.sidebar .btn-link {
    @extend .sidebar-a-base;
    font-weight: 400;
}

.sidebar svg {
    margin-right: 12px;
}