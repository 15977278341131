.recipe-form-small th,
.recipes-container th {
    @extend label;
}

.recipe-form-small thead tr th {
    padding-bottom: 8px;
}

.recipes-container table {
    max-width: inherit;
    width: inherit;
    border-collapse: separate;
    border-spacing: 0 8px;
}

.recipes-container th:nth-child(3),
.recipes-container td:nth-child(3),
.recipes-container th:nth-child(4),
.recipes-container td:nth-child(4) {
    text-align: center;
}

.recipes-container td:last-of-type {
    display: flex;
    justify-content: flex-end;
    flex-direction: row;
    height: inherit;
}

.recipes-container th:last-of-type {
    width: calc(38px + 38px);
}

.recipes-container td {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.44px;

    border-top: 1px solid #DDD;
    border-bottom: 1px solid #DDD;
}

.recipes-container th:first-child {
    padding-left: 18px;
}

.recipes-container td:first-child {
    border-radius: 53px 0px 0px 53px;
    border: 1px solid #DDD;
    border-right: none;
    padding-left: 14px;
}

.recipes-container td:last-child {
    border-radius: 0px 53px 53px 0px;
    border: 1px solid #DDD;
    border-left: none;
    padding-right: 14px;
}

.recipes-container th {
    padding-bottom: 9px;
}

.recipes-container tbody tr {
    height: 57px;
    margin-bottom: 8px;
}