.recipes-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1313px;
    width: 100%;
}

.recipes-container .heading {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    max-width: inherit;
    width: inherit;
}

.recipes-container .heading .btn-row {
    display: flex;
    justify-content: center;
    gap: 14px;
}

.recipes-container hr {
    height: 1px;
    max-width: inherit;
    width: inherit;
    background: linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), linear-gradient(0deg, rgba(0, 0, 0, 0.20) 0%, rgba(0, 0, 0, 0.20) 100%), #000;
    opacity: 1;
    margin: 5px 0px 64px 0px;
}