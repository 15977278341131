.profile-container {
    display: flex;
    flex-direction: column;
    flex: auto;
    padding: 97px 80px;
}

.profile {
    max-width: 1313px;
    width: 100%;
}

form.profile .main-info {
    display: flex;
    margin-bottom: 80px;
}


form.profile .user-data {
    flex: 1;
}

form.profile .user-data>div {
    display: flex;
}

form.profile .user-data h1 {
    color: #000;
    font-family: Inter;
    font-size: 44px;
    font-style: normal;
    font-weight: 600;
    line-height: 50px;
    letter-spacing: -0.88px;
}

form.profile .user-data div:has(h1) {
    margin: 4px 0px;
}

form.profile .user-data h2 {
    color: #8A8A8A;
    font-family: Inter;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.48px;
}

form.profile .user-data div:has(h2) {
    margin-bottom: 32px;
}

form.profile .user-data h3 {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 32px;
    font-style: normal;
    font-weight: 500;
    line-height: 48px;
    letter-spacing: -0.704px;
}

form.profile .main-info>button {
    width: auto;
    height: 40px;
    padding: 0px 6px;
    border-radius: 4px;
    background: #C7DFF4;
    border: none;
    outline: none;

    color: #121212;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: -0.4px;
}


form.profile button {
    margin-left: 14px;
}

form.profile .statistics {
    display: flex;
    justify-content: flex-start;
    gap: 24px;
    margin-bottom: 57px;
}

form.profile .statistics>div {
    width: auto;
    height: auto;
    border-radius: 12px;
    padding: 9px 32px;
    border: 1.25px solid #8A8A8A;

    display: flex;
    flex-direction: column;
    align-items: center;
}

form.profile .statistics label:first-child {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 28px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.56px;

    margin-bottom: 4px;
}

form.profile .statistics label:nth-child(2) {
    color: #8A8A8A;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.2px;
}

form.profile .description {
    display: flex;
    flex: 1;
    align-items: flex-start;

    margin-bottom: 125px;
}

form.profile .description>div {
    flex: 1;
    color: #000;
    font-family: Inter;
    font-size: 22px;
    font-style: normal;
    font-weight: 500;
    line-height: 22px;
    letter-spacing: -0.22px;
}