.language-title-row {
    @extend .flex;

    justify-content: space-between;
    gap: 184px;
}

.language-title-row.no-gap {
    gap: 34px
}

@media screen and (max-width: 1600px) {
    .language-title-row {
        gap: 32px;
    }
}

.language-title-row>.form-group>div {
    flex: 1;
    width: 100%;
}

.form-group>div.basic-single,
.form-group-invisible>div.basic-single {
    flex: 1;
    width: 100%;
}

.recipe-form-small th {
    color: #1E1E1E;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.308px;
}

.recipe-form-small thead tr th {
    padding-bottom: 8px;
}

.preparation ul {
    padding-left: 0px;
    margin-bottom: 0px;
}

.preparation li {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.preparation li:last-of-type {
    margin-bottom: 0px;
}

.recipe-form-medium .button-row {
    margin-top: calc(74px - 32px);

    display: flex;
    flex-direction: row;
    justify-content: center;

    gap: 24px;
}