.login-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}

.login-container h1,
.login-container>.btn-link {
    @extend label;
    align-self: flex-start;
    margin-left: 85px;
    margin-bottom: 57px;
}

.login-container form {
    max-width: 570px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: auto;
    gap: 24px
}