.main-form-content {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex: 1;

    background-size: cover;
    background-position: center
}

.img-background {
    background-size: cover;
    background-position: center
}

.base-media-input {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center !important;
    flex: 1 1;
    flex-grow: 1 !important;
    gap: 23px;

    width: -webkit-fill-available;
    height: -webkit-fill-available !important;
    max-width: -webkit-fill-available;
    min-width: -webkit-fill-available !important;

    outline: none !important;
    border-radius: 24px;
    margin-top: 96px;
    margin-left: 74px;
    margin-bottom: calc(44px + 57px + 67px);
    margin-right: 74px;
}

.media-input,
.media-input:focus-within {
    @extend .base-media-input;
    border: 1.25px solid #DDD !important;
}

@media screen and (max-width: 1190px) {
    .media-input {
        padding-left: 16px;
        padding-right: 16px;
    }
}

.invisible-media-input {
    @extend .base-media-input;
    border: none !important;
}

.absolute-media-input {
    @extend .invisible-media-input;

    position: absolute !important;
    width: 1165px;
    border: none !important;
}

.media-input>div,
.invisible-media-input>div {
    border-radius: 24px !important;
    color: transparent;
}

.main-form-content .recipe-video {
    width: 1165px !important;
    height: -webkit-fill-available !important;
    background-color: #000;
}

.main-form-content .info-text {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: -0.4px;
}

.main-form-content .btn-row-small {
    position: absolute;
    max-width: 1165px;
    bottom: calc(44px + 57px + 56px);
    right: calc(calc(1165px / 2) - 12px - 422px);

    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

@media screen and (max-width: 1190px) {
    .main-form-content .btn-row-small {
        right: 16px;
    }
}

.carousel-container {
    height: 200px;
    max-width: calc(24px + 422px + 422px);
    background-color: #fff;
    border-radius: 4px;
}

.carousel-container>ul {
    height: inherit;
    width: inherit;
    background-color: rgb(0, 247, 255);
    padding: 20px;
    gap: 12px;
}

.modal-body-media .carousel-item {
    display: flex !important;
    width: 160px;
    height: 160px;
    background-color: red;
}


.parent {
    position: absolute;
    bottom: calc(44px + 57px + 56px + 36px + 33px);
    right: calc(calc(1165px / 2) - 12px - 422px);
    background-color: #FFF;
    width: auto;
    max-width: calc(24px + 422px + 422px);
    height: 200px;
    border-radius: 4px;
    z-index: 999;
}

@media screen and (max-width: 1190px) {
    .parent {
        right: 16px;
        max-width: calc(100vw / 2);
    }
}

.slider {
    overflow: "hidden";
    position: relative;
}

.slider img {
    width: 160px;
    height: 160px;
    border-radius: 4px;
    object-fit: cover;
}

.react-multi-carousel-list {
    padding: 20px;
}

.react-multi-carousel-list li {
    width: 160px !important;
    margin-right: 12px;
}

.react-multi-carousel-list li:last-of-type {
    margin-right: 0px;
}

.react-multi-carousel-dot-list li {
    width: auto !important;
}

.custom-dot-list-style button {
    border: none;
    background: #ffcd0533;
    width: 32px;
    border-radius: 4px;
}

.react-multi-carousel-dot.react-multi-carousel-dot--active button {
    background: $yellow !important;
}

.slider.add-new-image {
    width: 160px;
    height: 160px;
    border-radius: 4px;
    background: #DDD;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider.add-new-image label {
    color: #000;
    text-align: center;
    font-family: Inter;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.36px;
    height: -webkit-fill-available;
    align-content: center;
}

.slider.add-new-image label:hover {
    cursor: pointer;
}

.carousel-btn-row {
    position: absolute;
    bottom: 2px;
    right: 2px;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 4px;
}

.carousel-loader {
    position: absolute;
    bottom: calc(44px + 57px + 56px + 36px + 33px);
    right: calc(calc(1165px / 2) - 12px - 422px);
    background-color: #fff;
    width: 200px;
    height: 200px;
    border-radius: 4px;
    z-index: 999;
    display: flex;
    justify-content: center;
    align-items: center;
}

.recipe-modal>.spinner {
    display: flex;
    flex: 1;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
    height: -webkit-fill-available;
}

.media-uploading {
    position: fixed;
    bottom: 36px;
    right: 0px;
    background-color: rgb(255, 255, 255);
    border-top: 8px solid $yellow;
    width: 350px;
    height: 70px;
    z-index: 99999;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding-left: 36px;
    padding-right: 36px;
    gap: 16px;
}

.media-uploading .spinner-border {
    flex: none;
}