.modal-body-translation .carousel,
.modal-body-preview .carousel {
    background-color: lavender;
    display: flex;
    flex: auto;
    width: 100%;
    align-items: center;
    justify-content: center;
}

.modal-body-translation .carousel-indicators,
.modal-body-preview .carousel-indicators {
    bottom: 90px;
}

.modal-body-translation .carousel-inner,
.modal-body-preview .carousel-inner {
    width: 100%;
    height: 100%;
    background-color: palegoldenrod;
}

.modal-body-translation .carousel-item,
.modal-body-preview .carousel-item {
    height: 100%;
    justify-content: center;
    float: left;
}

.modal-body-translation .carousel-item img,
.modal-body-preview .carousel-item img {
    width: 100% !important;
    object-fit: cover !important;
    height: calc(100vh - 37px - 64px) !important;
}

.recipe-content-translation,
.recipe-content-preview {
    background-color: #121212;
    padding: 70px 80px;

    max-width: 752px;
    gap: 64px;
}

@media screen and (max-width: 1800px) {

    .recipe-content-translation,
    .recipe-content-preview {
        padding: 64px 70px;
    }
}

@media screen and (max-width: 1600px) {

    .recipe-content-translation,
    .recipe-content-preview {
        padding: 64px 16px;
    }
}

.preparation-base {
    color: #8A8A8A;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    line-height: 15px;
    letter-spacing: -0.14px;
}

.recipe-content-translation .preparation,
.recipe-content-preview .preparation {
    @extend .preparation-base;
    font-weight: 500;
}

.recipe-content-translation .preparation b,
.recipe-content-preview .preparation b {
    @extend .preparation-base;
    font-weight: 700;
}

.recipe-content-translation hr,
.recipe-content-preview hr {
    height: 1px;
    background-color: #ABABAB;
    opacity: 1;
    border: none;
    margin-top: 56px;
    margin-bottom: 18px;
}

.recipe-content-translation h2,
.recipe-content-preview h2 {
    color: #F8F2EA;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    letter-spacing: -0.28px;
    margin-bottom: 18px;
}

.recipe-content-translation h3,
.recipe-content-preview h3 {
    color: $yellow;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 125% */
    letter-spacing: -0.32px;
}

.recipe-content-translation ul,
.recipe-content-preview ul {
    list-style: none;
    padding: 0px;
    margin: 0px 0px 72px 0px;
}

.recipe-content-translation ul:not(.ingredients) li,
.recipe-content-preview ul:not(.ingredients) li {
    margin-bottom: 16px;
}

.preview-li-base {
    color: #D0D0D0;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    line-height: 20px;
    letter-spacing: -0.32px;
}

.recipe-content-translation li,
.recipe-content-preview li {
    @extend .preview-li-base;
    font-weight: 500;
}

.recipe-content-translation li b,
.recipe-content-preview li b {
    @extend .preview-li-base;
    font-weight: 600;
}

.preview-ingredients-heading,
.preview-ingredients-heading div:first-of-type {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.preview-ingredients-heading {
    justify-content: space-between;
}

.preview-ingredients-heading div:first-of-type {
    justify-content: flex-end;
}

.preview-ingredients-heading div:first-of-type h2 {
    margin-right: 16px;
}

.preview-ingredients-heading h2 {
    margin-bottom: 0px;
}

.preview-ingredients-heading .form-group .deco-number-input input {
    background-color: transparent !important;
    height: auto;
    color: white !important;
}

.preview-ingredients-heading .form-group .deco-number-input svg path {
    stroke: white !important;
}

.recipe-content-preview .tag {
    border-radius: 2px;
    background: #D9D9D9;
    display: inline-flex;
    padding: 0px 6px;
    justify-content: center;
    align-items: center;
    align-content: center;
    flex-wrap: wrap;

    color: #121212;
    font-family: Inter;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 170%;
    /* 17px */
    letter-spacing: -0.2px;
}

.recipe-content-preview div:has(.tag) {
    display: flex;
    gap: 8px;
}