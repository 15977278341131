.recipe-form-base {
    display: flex;
    flex-direction: column;
    background-color: white;
}

.recipe-form-large {
    @extend .recipe-form-base;
    max-width: 752px;
    padding: 64px 141px;
    gap: 64px;
}

@media screen and (max-width: 1800px) {
    .recipe-form-large {
        padding: 64px 70px;
    }
}

@media screen and (max-width: 1600px) {
    .recipe-form-large {
        padding: 64px 16px;
    }
}

.recipe-form-medium {
    @extend .recipe-form-base;
    padding: 56px 46px;
    gap: 32px;
}

.recipe-form-small {
    @extend .recipe-form-base;
    padding: 56px 46px;
    gap: 16px;
}

.form-group-base {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    gap: 4px;
    justify-content: flex-start;
    align-items: flex-start;
    position: relative;
}

.form-group,
.form-group-invisible {
    @extend .form-group-base;
}

.base-input-text {
    color: #000;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
    letter-spacing: -0.32px;
}

.form-group .base-input {
    border: 1.25px solid #DDD;
    flex-shrink: 0;
    width: 100%;
    padding-left: 14px;

    @extend .base-input-text;
}


.form-group-invisible .base-input {
    border: 1.25px solid transparent;
    flex-shrink: 0;
    width: 100%;
    padding-left: 14px;

    @extend .base-input-text;
}

.form-group-invisible .base-input:focus {
    border: 1.25px solid #DDD;

    @extend .base-input-text;
}

.base-input-placeholder {
    color: #686868;
    text-align: left;
    font-family: Inter;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
    /* 19.2px */
    letter-spacing: -0.32px;
}

.form-group .base-input::placeholder,
.form-group-invisible .base-input::placeholder {
    @extend .base-input-placeholder;
}

.form-group input,
.form-group-invisible input {
    @extend .base-input;
    height: 57px;
    border-radius: 36px;
}

.form-group textarea,
.form-group-invisible textarea {
    @extend .base-input;
    border-radius: 24px;
    padding-top: 12px;
}

.form-group .basic-single,
.form-group-invisible .basic-single {
    height: 57px;
}

.select__control-base {
    border-radius: 36px;
    outline: none !important;
    box-shadow: none !important;
    height: inherit;
}

.form-group .select__control {
    @extend .select__control-base;
    border: 1.25px solid #DDD !important;
}

.form-group-invisible .select__control {
    @extend .select__control-base;
    border: 1.25px solid transparent !important;
}

.form-group-invisible .select__control--is-focused {
    @extend .select__control-base;
    border: 1.25px solid #DDD !important;
}

.form-group .select__value-container,
.form-group-invisible .select__value-container {
    padding-top: 0px;
}

.form-group .select__input-container,
.form-group-invisible .select__input-container {
    height: inherit;
    margin: 0px;
    padding-top: 0px;
}

.form-group .select__control>*,
.form-group-invisible .select__control>* {
    height: inherit;

    padding-left: 14px;
}

.form-group .select__placeholder,
.form-group-invisible .select__placeholder {
    @extend .base-input-placeholder;
}

.form-group .select__single-value,
.form-group .select__menu *,
.form-group-invisible .select__single-value,
.form-group-invisible .select__menu * {
    @extend .base-input-text;
}

.form-group .select__option,
.form-group-invisible .select__option {
    background-color: transparent !important;
}

.form-group .select__option--is-focused,
.form-group-invisible .select__option--is-focused {
    background-color: #ffcd0533 !important;
}

.form-group .select__option--is-selected,
.form-group-invisible .select__option--is-selected {
    background-color: $yellow !important;
}

.form-group-invisible .select__indicator-separator {
    background-color: transparent;
}

.form-group-invisible .select__dropdown-indicator {
    color: transparent;
}

.form-group-invisible .select__control--is-focused .select__indicator-separator {
    background-color: hsl(0, 0%, 80%);
}

.form-group-invisible .select__control--is-focused .select__dropdown-indicator {
    color: hsl(0, 0%, 40%);
    ;
}

.recipe-form-small td:not(:nth-last-of-type(2)) {
    padding-right: 8px;
}

.recipe-form-small td:last-of-type {
    padding-right: 0px;
}

.modal-body-subtitles form>* {
    margin-bottom: 32px;
}

.recipe-content-translation .form-group {
    align-items: flex-end;
    margin-bottom: 34px;
}

.recipe-content-translation .form-group .basic-single {
    width: 40%;
    min-width: 100px;
}

.recipe-content-translation .form-group .select__control {
    background-color: transparent !important;
}

.recipe-content-translation .form-group .select__control .select__single-value {
    color: white;
}

.char-limit {
    color: #DDD;
    margin-left: 8px
}

.char-limit.warning {
    color: #f9ad08;
}

.char-limit.danger {
    color: #f24130;
}